import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator, MatCheckboxChange } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { invoiceMaster, invoiceDetailsMaster, customerMaster, JasperReport, invoiceReqMaster, serviceMaster, companeyMaster } from '../shared/AllModel';
import { getMaxInvoiceId, getActiveCategoryMasterURL, getServiceListByCategoryId, customerMobileWiseSearchURL, serviceNameWiseSearchURL, getCompanyDetailsByCompanyId, getDetailsByInvoiceMaster, createInvoiceMasterURL, getByInvoiceId, editByInvoiceId, getEntityNameWiseCategoryListURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {

  invoiceMasterForm: FormGroup;
  invoiceMasterModel = new invoiceMaster();
  invoiceDetailsModel = new invoiceDetailsMaster();
  customerModel = new customerMaster();
  jasperReport= new JasperReport();
  customerList: Array<customerMaster>;
  categoryList:any;
  invoiceReqModel = new invoiceReqMaster();
  serviceModel = new serviceMaster();
  serviceList: any;
  companeyMasterModel = new companeyMaster();
  companyList:any;
  serviceList1: any = [];
  finalAmt: number = 0;


  isEdit: boolean = true;
  hide = true;
  flag: boolean = false;
  index: number = 0;
  date:any;

  professionalFees:number;
  discountPer: number;
  finalDiscountAmt: number;
  finalAmt1: number=0;
  maxInvoiceId: string;
  showDraft:boolean=false;
  descflag: boolean = false;
  govFee: number;
  totalGovermentFee: number = 0;
  minFromDate = new Date().setDate(2);
  maxToDate = new Date();
  minFromDate1 = new Date()
  maxToDate1 = new Date();
  role: any;
  roleFlag: boolean = true;
  roleFlag1: boolean = true;
  selectedAreas:string[];
  selectedAreas1:string[];
  entityFlag: boolean = true;
  entityFlag1: boolean = false;
  entityFees: number;
  serviceName:string;
  invoiceFlag:boolean=false;
  entityName:string;


  constructor(private route: ActivatedRoute,private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'name', 'charges','discount',];
  dataSource = new MatTableDataSource<serviceMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  @ViewChild('picker', { static: false }) picker: any;



  ngOnInit() {
    this.invoiceMasterForm = this.formBuilder.group({
      mobno: new FormControl('',[Validators.required,]),
      name: new FormControl('',),
      customerName: new FormControl('',[Validators.required,]),
      address: new FormControl('',[Validators.required,]),
      service: new FormControl('',),
      invoice: new FormControl('',),
      final: new FormControl('',),
      charges: new FormControl('',[Validators.required,]),
      // charges1: new FormControl('',[Validators.required,]),
      name1: new FormControl('',),
      gst: new FormControl('',),
      // pending: new FormControl('',[Validators.required,]),
      description: new FormControl('',[Validators.required,]),
      discountPercent: new FormControl('',),
      discount1: new FormControl(''),
      pan: new FormControl('',[Validators.required,]),
      dateTime: new FormControl('',[Validators.required,]),
      draft: new FormControl('',),
      draftname: new FormControl('',),

    })
    this.getCompany();
    this.getActiveCategory();
    this.getMaxInvoiceId();
    this.date=new Date();
    
    
    this.invoiceMasterModel.saveAsDraft=false;
    if(sessionStorage.getItem("AddFlag") != "true"){

    let invoiceFlag = sessionStorage.getItem("editFlag");
    if (invoiceFlag === 'true') {

      this.httpService.getRequest(editByInvoiceId + "/" + Number(sessionStorage.getItem("invoiceId"))).subscribe((data: any) => {
        this.invoiceReqModel.invoiceNo = data.invoiceNo;
        this.invoiceMasterModel.dateTime = data.dateTime;
        this.customerModel.customerMobileNo = data.customerMobileNo;
        this.customerModel.customerName = data.customerName;
        this.customerModel.customerAddress = data.customerAddress;
        this.customerModel.gstNo = data.gstNo;
        this.customerModel.panNo = data.customerPanNo;


        this.httpService.getRequest(getByInvoiceId + "/" + Number(sessionStorage.getItem("invoiceId"))).subscribe((data: any) => {
          this.serviceList1 = data;
          this.descflag = true;

          this.govFee = 0;
          for (let i = 0; i < this.serviceList1.length; i++) {
            this.govFee = this.govFee + this.serviceList1[i].governmentFees;
          }
          this.totalGovermentFee = this.govFee;

          this.dataSource = new MatTableDataSource(this.serviceList1);
          this.dataSource.paginator = this.paginator;
        })

        this.finalAmt = data.finalAmt;
        this.serviceModel.discountPercent1 = data.discountPercent;
        this.finalAmt1 = data.finalAmt;



      })
    

    }
    else {
      this.route.queryParams
        .subscribe(params => {
          console.log("Pro Forma=" + params);

          if (this.invoiceMasterModel == null || this.serviceModel == null) {
            this.descflag = false;
          }
          else {


            this.httpService.getRequest(getByInvoiceId + "/" + Number(sessionStorage.getItem("invoiceId"))).subscribe((data: any) => {
              this.serviceList1 = data;
              this.descflag = true;
              this.dataSource = new MatTableDataSource(this.serviceList1);
              this.dataSource.paginator = this.paginator;
              this.finalAmt = data.finalAmt;
              this.serviceModel.discountPercent1 = data.discountPercent;
              this.finalAmt1 = data.finalAmt;
            })
          }

        }
        );
    }
  }

    
    this.role = sessionStorage.getItem("role");

    if (this.role == "Admin") {
      this.roleFlag = false;
      this.roleFlag1 = true;
    }
    else {
     this.roleFlag = true;
     this.roleFlag1 = false;

    } 
    this.serviceModel.discountPercent=0;

  }

  getMaxInvoiceId()
  {
    this.httpService.getRequest(getMaxInvoiceId).subscribe((data: any) => {
      this.maxInvoiceId = data;
      this.invoiceReqModel.invoiceNo="2021/"+this.maxInvoiceId;
    })
  }

  search(query: string){
    console.log('query', query)
    let result = this.select(query)
    this.selectedAreas = result;
  }

  select(query: string):string[]{
    let result: string[] = [];
    for(let category of this.categoryList){
      if(category.name.toLowerCase().indexOf(query) > -1){
        result.push(category)
      }
    }
    return result
  }

  search1(query: string){
    console.log('query', query)
    let result1 = this.select1(query)
    this.selectedAreas1 = result1;
  }

  select1(query: string):string[]{
    let result1: string[] = [];
    for(let service of this.serviceList){
      if(service.name.toLowerCase().indexOf(query) > -1){
        result1.push(service)
      }
    }
    return result1
  }


  // entityCheck(e) {
  //   if (e.value == "Small Entity" || e.value == "Natural Person") {
  //     this.entityFlag = true;
  //     this.entityFlag1 = false;
  //     this.getService1(e);

  //   }
  //   else {
  //     this.entityFlag1 = true;
  //     this.entityFlag = false;
      
      
  //     this.getService2(e);
  //   }

  // }

  entityCheck(e) {

    if (e.value == "Small Entity") {
      this.entityName="entityFees";
      this.entityFlag = true;
      this.invoiceFlag=true;
      this.getService1(e);
    }
    else if (e.value == "Other Than Small Entity") {
      this.entityName="otherThanSmallEntity";

      this.entityFlag = true;
      this.invoiceFlag=true;

      this.getService1(e);

    }
    else if (e.value == "Natural Person") {
      this.entityName="naturalPerson";

      this.entityFlag = true;
      this.invoiceFlag=true;

      this.getService1(e);

    }
  }



  getActiveCategory()
  {
    this.httpService.getRequest(getActiveCategoryMasterURL).subscribe((data: any) => {
      this.categoryList = data;
      this.selectedAreas=this.categoryList;

    })
  }

  getServiceList(e)
  {
    this.httpService.getRequest(getEntityNameWiseCategoryListURL + "/" + e.value + "/" + this.entityName).subscribe((data: any) => {
      this.selectedAreas1 = data;
    
      
    })
  }
  

  getSearchCustomer(event) {
    this.customerModel.customerMobileNo = event.target.value;
    this.httpService.getRequest(customerMobileWiseSearchURL + "/" + this.customerModel.customerMobileNo).subscribe((data: any) => {
      this.customerList = data;

    })

  }

  getSearchService(event) {
    this.serviceModel.serviceName = event.target.value;
    this.httpService.getRequest(serviceNameWiseSearchURL + "/" + this.serviceModel.serviceName).subscribe((data: any) => {
      this.serviceList = data;

    })

  }

  


  getCustomer() {
    this.httpService.getRequest(customerMobileWiseSearchURL + "/" + this.customerModel.customerMobileNo).subscribe((data: any) => {
      this.customerList = data;
      this.customerModel = new customerMaster();
    })

  }

  getCompany() {
    this.httpService.getRequest(getCompanyDetailsByCompanyId + "/" + Number(sessionStorage.getItem("compId"))).subscribe((data: any) => {
      this.companeyMasterModel = data;
      
    })

  }

  getService() {
    this.httpService.getRequest(serviceNameWiseSearchURL + "/" + this.serviceModel.serviceName).subscribe((data: any) => {
      this.serviceList = data;
      this.serviceModel = new serviceMaster();
    })

  }
  getCustomer1(customer) {
    this.customerModel = customer;
 
  }
  getService1(service) {
    this.serviceModel = service; 
    this.serviceName=service.serviceName;
    this.serviceModel.serviceId=service.serviceId;
    this.serviceModel.categoryId=service.categoryMaster.categoryId;
    this.serviceModel.serviceName="Professional Fees for "+service.serviceName;
    this.serviceModel.description=service.serviceName;
    console.log(service);
    this.serviceModel.discountPercent=0;
    if(this.entityFlag){
      this.serviceModel.professionalFees=service.professionalFees;
    }
    else{
      this.serviceModel.professionalFees=service.entityFees;
    }

  }

  
  changeInputNo(e:any) {
    
    this.invoiceReqModel.invoiceNo = e.target.value
  }

  getService2(service) {
    this.serviceModel = service;
    this.serviceModel.serviceId = service.serviceId;
    this.serviceModel.categoryId = service.categoryMaster.categoryId;
    this.serviceModel.description = "Professional Fees for " + service.description;
    // this.serviceModel.discountPercent = 0;
    this.serviceModel.entityFees = service.entityFees;
  }

  
///////save draft////
eventCheck(event:MatCheckboxChange){
  if(event.checked)
  {
    this.showDraft = true;
  }
  else
  {
    this.showDraft = false;
  }
}

  
saveToDraft() {
  this.invoiceMasterModel.invoiceType = "INVOICE";
this.router.navigate(['/layout/savedraftinvoice'], { queryParams: { invoiceType: this.invoiceMasterModel.invoiceType, saveAsDraft: this.invoiceMasterModel.saveAsDraft } });

}

  addToList(serviceModel) {

    if (this.serviceList1.length == 0) {
    // console.log(serviceModel)

      this.serviceList1.push(serviceModel);
      this.descflag=false;
      this.dataSource = new MatTableDataSource(this.serviceList1);
      this.dataSource.paginator = this.paginator;
      this.serviceModel = new serviceMaster();

      this.discountPer=this.serviceModel.discountPercent;
      this.serviceList=[];
      this.calculation();
    }
    else {
      for (let i = 0; i < this.serviceList1.length; i++) {
        if (this.serviceList1[i].serviceName == serviceModel.serviceName) {
          this.flag = true;
          this.index = i;
          break;
        }
        else
        {
          this.flag = false;
        }

      }

      
      if (this.flag == true) {
        this.serviceList1[this.index].professionalFees = this.serviceModel.professionalFees + this.serviceList1[this.index].professionalFees;
        this.finalAmt = this.serviceList1[this.index].professionalFees;
        this.descflag=false;
        this.dataSource = new MatTableDataSource(this.serviceList1);
        this.dataSource.paginator = this.paginator;
        this.serviceModel = new serviceMaster();

        this.discountPer=this.serviceModel.discountPercent;
        this.serviceList=[];
        this.calculation();

      }
      else {
        this.serviceList1.push(serviceModel);
      
        this.dataSource = new MatTableDataSource(this.serviceList1);
        this.dataSource.paginator = this.paginator;
        this.serviceModel = new serviceMaster();

        this.discountPer=this.serviceModel.discountPercent;
        this.serviceList=[];
        this.calculation();
      }
    }
    
  }

  changepayment(e:any) {
    
    this.invoiceMasterModel.paymentReceipt = e.target.value;

  }

  orderInvoice(invoiceId) {

    this.jasperReport.invoiceId = invoiceId;
    let newWindow = window.open();
    this.httpService.postRequest(getDetailsByInvoiceMaster, this.jasperReport).subscribe((response: any) => {
      newWindow.location.href = response.url;
      
    })
  }


  calculation(){
      
    this.finalAmt=0;
    
       for (let j = 0; j < this.serviceList1.length; j++) {
 
        this.finalAmt=this.finalAmt+this.serviceList1[j].professionalFees;
        //////govermentfee////////
 
        this.finalAmt1=this.finalAmt; 
      
       }
 
     }



  invoiceMaster() {

    this.invoiceReqModel.customerMaster = this.customerModel;
    this.invoiceReqModel.invoiceType = "INVOICE";
    this.invoiceReqModel.note = ".";
    this.invoiceReqModel.dateTime=this.invoiceMasterModel.dateTime;
    this.invoiceReqModel.paymentReceipt = this.invoiceMasterModel.paymentReceipt;

    this.invoiceReqModel.finalAmt = this.finalAmt;
    if(this.invoiceMasterForm.controls['discount1']!=null){
      this.invoiceReqModel.finalAmt = this.finalAmt1;
    }
    
    this.invoiceReqModel.empId = Number(sessionStorage.getItem("empId"));
    for(let i = 0; i< this.serviceList1.length; i++) {
      this.serviceList1[i].serviceName = this.serviceList1[i].description
    }
    // console.log(this.serviceList1);

    this.invoiceReqModel.invoiceDetailsMasterList = this.serviceList1;
    this.invoiceReqModel.saveAsDraft=this.invoiceMasterModel.saveAsDraft;
    this.invoiceReqModel.draftName=this.invoiceMasterModel.draftName;
    // console.log(this.invoiceReqModel);
    
    this.httpService.postRequest(createInvoiceMasterURL, this.invoiceReqModel).subscribe((data: any) => {
      if (data.invoiceId!=null) {
        this.isEdit = true;
        this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
        // this.saveToDraft();
        this.router.navigateByUrl('/layout/invoicereport');

        this.invoiceMasterForm.reset();

        this.orderInvoice(data.invoiceId);

      }
      else {
        this.toastr.errorToastr("Please add customer details.", "Error");
        
      }
    })
  }

  getInvoiceById(element, i) {
    this.serviceModel = element;
    this.serviceList1.splice(i, 1);
    this.dataSource.data = this.serviceList1;
  }

  removeData(index) {
    this.serviceList1.splice(index, 1);
    this.dataSource.data = this.serviceList1;
  }


  calculateDiscountOnProfestionalFees(){
    this.professionalFees=(this.serviceModel.professionalFees * this.serviceModel.discountPercent)/100;
    this.serviceModel.professionalFees=this.serviceModel.professionalFees-this.professionalFees;
    
  }

  calFinalAmtByPercent()
  {
    this.finalDiscountAmt=(this.finalAmt * this.serviceModel.discountPercent1)/100;
    this.finalAmt1=this.finalAmt-this.finalDiscountAmt;

  }
}
