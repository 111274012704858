import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { element } from 'protractor';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  role: any;
  roleFlag: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {
    $(document).ready(function () {
      $('ul li a').click(function () {
        $('li a').removeClass("active");
        $(this).addClass("active");
      });
    });

    this.role = sessionStorage.getItem("role");

    if (this.role == "Admin") {
      this.roleFlag = true;

    }
    else {
      this.roleFlag = false;


    }

 
      
  }


}
