import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { EmailTemplateMaster } from '../shared/AllModel';
import { createEmailTemplateMasterURL, getAllEmailTemplateMasterDetailsURL, getEmailTemplateDetailsByTemplateEmailIdURL, updateEmailTemplateMasterURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as xlsx from 'xlsx';
import { AngularEditorConfig } from '@kolkov/angular-editor/lib/config';




@Component({
  selector: 'app-templatemaster',
  templateUrl: './templatemaster.component.html',
  styleUrls: ['./templatemaster.component.css']
})
export class TemplatemasterComponent implements OnInit {


  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
 
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };



  emailTemplateMasterForm: FormGroup;
  emailTemplateMasterModel = new EmailTemplateMaster();
  isEdit: boolean = true;
  hide = true;


  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'tname', 'des', 'Status', 'Action'];
  dataSource = new MatTableDataSource<EmailTemplateMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;



  ngOnInit() {
    this.emailTemplateMasterForm = this.formBuilder.group({
      templateHeading: new FormControl('', [Validators.required,]),
      templateDesc: new FormControl('',),
      templateStatus: new FormControl('', [Validators.required,]),

    })
    this.getAllEmailTemplate();
  }


  SaveEmailTemplate() {
    if (this.isEdit) {

      this.httpService.postRequest(createEmailTemplateMasterURL, this.emailTemplateMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllEmailTemplate();
          this.emailTemplateMasterForm.reset();


        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
    }
    else {
      this.httpService.putRequest(updateEmailTemplateMasterURL, this.emailTemplateMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllEmailTemplate();
          this.emailTemplateMasterForm.reset();

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }

  getAllEmailTemplate(){
    this.httpService.getRequest(getAllEmailTemplateMasterDetailsURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }


  getAllEmailDetailsById(templateId) {
    this.httpService.getRequest(getEmailTemplateDetailsByTemplateEmailIdURL + "/" + templateId).subscribe((data: any) => {
      this.emailTemplateMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }

  exportToExcel() {
    const ws: xlsx.WorkSheet =
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Email Template Details.xlsx');
    }
}
