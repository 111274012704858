import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Session } from 'selenium-webdriver';
import { companeyMaster, customerMaster, employeeMaster, JasperReport, PaymentReceiptMaster, UpdateMaster } from '../shared/AllModel';
import { clientWiseInvoiceNoURL, clientWiseReceiptPdfURL, createClientWiseReceiptURL, customerMobileWiseSearchURL, getByIdEmployeeMasterURL, getClientWiseReceiptId, getCompanyDetailsByCompanyId, testReportURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-paymentreceipt',
  templateUrl: './paymentreceipt.component.html',
  styleUrls: ['./paymentreceipt.component.css']
})
export class PaymentreceiptComponent implements OnInit {

  paymentMasterForm: FormGroup;
  paymentMasterModel = new PaymentReceiptMaster();
  paymentList: Array<PaymentReceiptMaster>;
  companeyMasterModel = new companeyMaster();
  customerList: Array<customerMaster>;
  employeeMasterModel = new employeeMaster();
  jasperReport = new JasperReport();
  companyList: any;
  customerModel = new customerMaster();
  isEdit: boolean = true;
  hide = true;
  Payment: boolean = false;
  customerId: number;
  minFromDate = new Date().setDate(2);
  maxToDate = new Date();
  minFromDate1 = new Date()
  maxToDate1 = new Date();
  role: any;
  roleFlag: boolean = true;
  roleFlag1: boolean = true;
  updateModel = new UpdateMaster();


  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Name', 'address', 'invoice', 'number', 'gst', 'amt',];
  dataSource = new MatTableDataSource<PaymentReceiptMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;


  ngOnInit() {
    this.paymentMasterForm = this.formBuilder.group({
      date: new FormControl('',),
      invoiceType: new FormControl('',),
      paidAmt: new FormControl('', [Validators.required,]),
      invoiceNo: new FormControl('', [Validators.required,]),
      paidBy: new FormControl(''),
      inFavourOf: new FormControl('',),
      chequeNeftNo: new FormControl('',),
      chequeNeftDate: new FormControl('',),
      chequeNeftBank: new FormControl('',),
      customerId: new FormControl('',),
      empId: new FormControl('',),
      mobno: new FormControl('', [Validators.required,]),
      gst: new FormControl('',),
      name: new FormControl('',),
      being: new FormControl('',),
    })
    this.getCompany();
    this.getEmployee();


    let invoiceFlag = sessionStorage.getItem("editFlag");
    if (invoiceFlag === 'true') {
      this.httpService.getRequest(getClientWiseReceiptId + "/" + Number(sessionStorage.getItem("clientwiseReceiptMasterId"))).subscribe((data: any) => {
        this.paymentMasterModel.date = data.date;
        this.customerModel.customerName = data.customerMaster.customerName;
        this.customerModel.gstNo = data.customerMaster.gstNo;
        this.paymentMasterModel.invoiceType = data.invoiceType;
        this.paymentMasterModel.invoiceNo = data.invoiceNo;
        this.paymentMasterModel.paidAmt = data.finalAmt;
        this.paymentMasterModel.paidBy = data.paidBy;
        this.paymentMasterModel.inFavourOf = data.inFavourOf;
        this.paymentMasterModel.chequeNeftNo = data.chequeNeftNo;
        this.paymentMasterModel.chequeNeftDate = data.chequeNeftDate;
        this.paymentMasterModel.chequeNeftBank = data.chequeNeftBank;
        this.paymentMasterModel.customerId = data.customerMaster.customerId;
        this.paymentMasterModel.clientwiseReceiptMasterId = data.clientwiseReceiptMasterId;
      })
    }

    this.role = sessionStorage.getItem("role");

    if (this.role == "Admin") {
      this.roleFlag = false;
      this.roleFlag1 = true;
    }
    else {
      this.roleFlag = true;
      this.roleFlag1 = false;
    }
  }

  getCustomer() {
    this.httpService.getRequest(customerMobileWiseSearchURL + "/" + this.customerModel.customerName).subscribe((data: any) => {
      this.customerList = data;
      this.customerModel = new customerMaster();
    })
  }

  getSearchCustomer(event) {
    this.customerModel.customerMobileNo = event.target.value;
    this.httpService.getRequest(customerMobileWiseSearchURL + "/" + this.customerModel.customerName).subscribe((data: any) => {
      this.customerList = data;
    })
  }

  getCustomer1(customer) {
    this.customerModel = customer;
  }


  getCompany() {
    this.httpService.getRequest(getCompanyDetailsByCompanyId + "/" + Number(sessionStorage.getItem("compId"))).subscribe((data: any) => {
      this.companeyMasterModel = data;
    })
  }

  getEmployee() {
    this.httpService.getRequest(getByIdEmployeeMasterURL + "/" + Number(sessionStorage.getItem("empId"))).subscribe((data: any) => {
      this.employeeMasterModel = data;
    })
  }


  eventCheck(e) {
    if (e.value == "Cheque" || e.value == "NEFT") {
      this.Payment = true;
    }
    if (e.value == "Cash" || e.value == "Mswipe" || e.value == "Online") {

      this.Payment = false;
    }
  }

  getInvoice() {
    this.httpService.getRequest(clientWiseInvoiceNoURL + "/" + this.paymentMasterModel.invoiceNo).subscribe((data: any) => {
      this.paymentList = data;
      this.paymentMasterModel = new PaymentReceiptMaster();
    })
  }

  getSearchInvoice(event) {
    this.paymentMasterModel.invoiceNo = event.target.value;
    this.httpService.getRequest(clientWiseInvoiceNoURL + "/" + this.paymentMasterModel.invoiceNo).subscribe((data: any) => {
      this.paymentList = data;

    })
  }

  getInvoice1(payment) {
    this.paymentMasterModel.paidAmt = payment.finalAmt;
    this.paymentMasterModel.invoiceNo = payment.invoiceNo;
  }

  orderInvoice(clientwiseReceiptMasterId) {
    this.jasperReport.clientwiseReceiptMasterId = clientwiseReceiptMasterId;
    let newWindow = window.open();
    this.httpService.postRequest(clientWiseReceiptPdfURL, this.jasperReport).subscribe((response: any) => {
      newWindow.location.href = response.filePath;
    })
  }


  insertPayment() {
    if (sessionStorage.getItem("editFlag") == null) {
      this.paymentMasterModel.customerId = this.customerModel.customerId;
    }
    else {
    }
    this.paymentMasterModel.empId = Number(sessionStorage.getItem("empId"));
    this.paymentMasterModel.date = this.paymentMasterModel.date;
    this.paymentMasterModel.invoiceType = this.paymentMasterModel.invoiceType;
    this.httpService.postRequest(createClientWiseReceiptURL, this.paymentMasterModel).subscribe((data: any) => {
      console.log(this.paymentMasterModel);
      if (data.clientwiseReceiptMasterId != null) {
        this.isEdit = true;
        this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
        this.orderInvoice(data.clientwiseReceiptMasterId);
        this.paymentMasterForm.reset();
        if (sessionStorage.getItem("editFlag") == 'true') {
          sessionStorage.removeItem("editFlag");
          this.router.navigateByUrl("/layout/receiptreport");
        }
        else {
          this.router.navigateByUrl("/layout/paymentreceipt");
        }
      }
      else {
        this.toastr.errorToastr("Something went Wrong.", "Error");
      }
    })
  }

}
