export class companeyMaster {
    compName: string;
    contactPersonName: string;
    mobNo1: string;
    mobNo2: string;
    emailId: string;
    address: string;
    compDesc: string;
    status: string;
    gstNo: string;
    compId: number;
    website: string;
    logo: string;
    checkPayableName: string;
    panNo: string;
    beneficiaryName: string;
    bankName: string;
    branchName: string;
    accountNo: string;
    ifscCode: string;
    micr: string;
    swistCode: string;

}

export class branchMaster {
    branchId: number;
    branchName: string;
    branchMobNo: number;
    branchEmailId: string;
    password: string;
    contactName: string;
    branchAdd: string;
    branchDes: string;
    branchStatus: string;
    compId: number;
    branchCode: string;

}

export class employeeMaster {
    empId: number;
    branchId: number;
    empName: string;
    empMob: string;
    empEmailId: string;
    empAdd: string;
    password: string;
    status: string;
    employeeId: string;
    role: string;
    compId: number;

}

export class categoryMaster {
    name: string;
    description: string;
    status: string;
    categoryId: number;
    compId: number;

}

export class serviceMaster {
    serviceId: number;
    serviceName: string;
    description: string;
    tempDesc: string;
    unit: string;
    status: string;
    categoryId: number;
    professionalFees: number;
    governmentFees: number;
    name: string;
    discountPercent: number;
    entityFees: boolean = false;
    naturalPerson: boolean = false;
    otherThanSmallEntity: boolean = false;
    discountPercent1: number;
    discount1: number;
    dateTime: Date;
}

export class customerMaster {
    branchId: number;
    customerAddress: string;
    customerId: number;
    customerMobileNo: string;
    customerName: string;
    customerStatus: string;
    emailId: string;
    gstNo: string;
    pendingAmount: number;
    contactPerson: string;
    contactPersonMobileNo: string;
    contactPersonEmail: string;
    panNo: string;

}

export class invoiceMaster {
    invoiceId: number;
    dateTime: Date;
    invoiceType: string;
    finalAmt: number;
    customerId: number;
    empId: number;
    saveAsDraft: boolean;
    draftName: string;
    description: string;
    description1: string;
    serviceName: string;
    categoryId: number;
    professionalFees: number;
    governmentFees: number;
    entityFees: number;
    pdfUrl: string;
    smallentity: string;
    otherentity
    invoiceNo: string;
    paymentReceipt: string;
    note: string;

}

export class invoiceDetailsMaster {
    invoiceDetailsId: number;
    serviceName: string;
    professionalFees: number;
    categoryName: string;

}
export class adminSetting {
    adminId: number;
    checkPayableName: string;
    panNo: string;
    beneficiaryName: string;
    bankName: string;
    branchName: string;
    accountNo: string;
    ifscCode: string;
    micr: string;
}

export class invoiceReqMaster {
    customerMaster: customerMaster;
    invoiceType: string;
    finalAmt: number;
    finalAmt2: number;
    empId: number;
    name: string;
    dateTime: Date;
    invoiceDetailsMasterList: Array<serviceMaster>;
    saveAsDraft: boolean = false;
    draftName: string;
    invoiceNo: string;
    entityAmt: number;
    paymentReceipt: string;
    note: string;

}

export class loginMaster {
    password: string;
    userName: string;
}

export class forgotPassword {
    userName: string;
}

export class JasperReport {
    invoiceId: Number;
    clientwiseReceiptMasterId: number;
}

export class InvoiceFilterReqDtoMaster {
    branchId: number;
    empId: number;
    customerId: number;
    serviceId: number;
    fromDate: string;
    toDate: string;
    invoiceType: string;
    templateId: number;
}

export class saveDraftMaster {
    dateTime: Date;
    description: string;
    invoiceType: string;
    serviceName: string;
    saveAsDraft: boolean = false;
    draftName: string;
    categoryId: number;
    professionalFees: number;
    governmentFees: number;
}

export class DashboardMaster {
    dailyCountOfInvoices: number;
    weeklyCountOfInvoices: number;
    monthlyCountOfInvoices: number;
    yearlyCountOfInvoices: number;
}

export class PaymentReceiptMaster {
    clientwiseReceiptMasterId: number;
    date: Date;
    invoiceType: string;
    finalAmt: string;
    paidAmt: string;
    invoiceNo: string;
    paidBy: string;
    inFavourOf: string;
    chequeNeftNo: string;
    chequeNeftDate: Date;
    chequeNeftBank: string;
    customerId: number;
    empId: number;
    being: string;
}

export class ReceiptReportMaster {
    customerId: number;
    empId: number;
}

export class EmailTemplateMaster {
    templateId: number;
    templateHeading: string;
    templateDesc: string;
    templateStatus: string;
}

export class SendMailMaster {
    templateId: number;
    invoiceId: number;
}

export class MailMaster {
    templateId: number;
    clientwiseReceiptMasterId: number;
}

export class UpdateMaster {
    clientwiseReceiptMasterId: number;
    date: Date;
    invoiceType: string;
    finalAmt: string;
    paidAmt: string;
    invoiceNo: string;
    paidBy: string;
    inFavourOf: string;
    chequeNeftNo: string;
    chequeNeftDate: Date;
    chequeNeftBank: string;
    customerName: string;
    gstNo: string;

}


// --------- Temp Class For Invoice

export class InvoiceClass {
    desc: string;
    fees: number;
    discout: number;
}

export class DynamicObjectClass {
    serviceId: number;
    serviceName: string;
    status: string;
    unit: number;
    categoryId: number;
    professionalFees: number;
    description: string;
    entityFees: number;
    categoryMaster: Array<any>;
    discountPercent: number;
}
export class DynamicObjectClass2 {
    serviceId: number;
    serviceName: string;
    status: string;
    unit: number;
    categoryId: number;
    // governmentFees: number;
    professionalFees: number;
    description: string;
    categoryMaster: Array<any>;
    discountPercent: number;
}