import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { serviceMaster } from '../shared/AllModel';
import { createServiceMasterURL, getActiveCategoryMasterURL, getAllServiceMasterURL, getByIdServiceMasterURL, importExcelURL, importToExcelByCompIdURL, updateServiceMasterURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as xlsx from 'xlsx';


@Component({
  selector: 'app-servicemaster',
  templateUrl: './servicemaster.component.html',
  styleUrls: ['./servicemaster.component.css']
})
export class ServicemasterComponent implements OnInit {
  keyword = 'name';

  serviceMasterForm: FormGroup;
  serviceMasterModel = new serviceMaster();
  ActiveCategoryList:any[];
  isEdit: boolean = true;
  hide = true;
  selectedAreas:string[];
  excelFile:any;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'category name','Name','des','professional','government', 'Status', 'Action'];
  dataSource = new MatTableDataSource<serviceMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.serviceMasterForm = this.formBuilder.group({
      serviceName: new FormControl('',[Validators.required,] ),
      description: new FormControl('',),
      status: new FormControl('', [Validators.required,]),
      categoryId: new FormControl('',[Validators.required,] ),
      professionalFees: new FormControl('',[Validators.required,]),
      governmentFees: new FormControl('', ),
      entityFees: new FormControl('', ),
      naturalPerson:new FormControl('', ),
      otherThanSmallEntity:new FormControl('', ),
    })
    this.getActiveCategory();
    this.getAllService();
  }



  getActiveCategory() {
    this.httpService.getRequest(getActiveCategoryMasterURL).subscribe((data: any) => {
      this.ActiveCategoryList = data;
      this.selectedAreas = this.ActiveCategoryList;

    })
  }

  search(query: string){
    let result = this.select(query)
    this.selectedAreas = result;
  }

  select(query: string):string[]{
    let result: string[] = [];
    for(let category of this.ActiveCategoryList){
      if(category.name.toLowerCase().indexOf(query) > -1){
        result.push(category)
      }
    }
    return result
  }

  test(e){
    if(e.value == "Small Entity"){
      this.serviceMasterModel.entityFees=true;
      this.serviceMasterModel.otherThanSmallEntity=false;
      this.serviceMasterModel.naturalPerson=false;
    }
    else if(e.value == "Other Than Small Entity"){
      this.serviceMasterModel.otherThanSmallEntity=true;
      this.serviceMasterModel.entityFees=false;
      this.serviceMasterModel.naturalPerson=false;


    }
    else if(e.value == "Natural Person"){
      this.serviceMasterModel.naturalPerson=true;
      this.serviceMasterModel.otherThanSmallEntity=false;
      this.serviceMasterModel.entityFees=false;

    }
  }

  SaveService() {
    if (this.isEdit) {

      this.httpService.postRequest(createServiceMasterURL, this.serviceMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllService();
          this.serviceMasterForm.reset();
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
    }
    else {
      this.httpService.putRequest(updateServiceMasterURL, this.serviceMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllService();
          this.serviceMasterForm.reset();
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }

  
  submitExcel() {
    let formData = new FormData();
    formData.append('uploadexcel', this.excelFile);

    this.httpService.excelUpload(importExcelURL, formData).subscribe((data: any) => {
      this.toastr.successToastr("Save Succesfully", "Success!", { timeOut: 500 });
    });
  }

  filee(e) {
    this.excelFile = e.target.files[0];
  }

  getAllService() {
    this.httpService.getRequest(getAllServiceMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  getServiceById(serviceId) {
    this.httpService.getRequest(getByIdServiceMasterURL + "/" + serviceId).subscribe((data: any) => {
      this.serviceMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  exportToExcel() {
    const ws: xlsx.WorkSheet =
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Service Details.xlsx');
    }
}
