import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CompanymasterComponent } from './companymaster/companymaster.component';
import { BranchmasterComponent } from './branchmaster/branchmaster.component';
import { EmployeemasterComponent } from './employeemaster/employeemaster.component';
import { ServicemasterComponent } from './servicemaster/servicemaster.component';
import { CategorymasterComponent } from './categorymaster/categorymaster.component';
import { CustomermasterComponent } from './customermaster/customermaster.component';
import { InvoicemasterComponent } from './invoicemaster/invoicemaster.component';
import { AdminsettingComponent } from './adminsetting/adminsetting.component';
import { EstimateComponent } from './estimate/estimate.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { DebitnoteComponent } from './debitnote/debitnote.component';
import { InvoicereportComponent } from './invoicereport/invoicereport.component';
import { SavedraftComponent } from './savedraft/savedraft.component';
import { SavedraftestimateComponent } from './savedraftestimate/savedraftestimate.component';
import { SavedraftinvoiceComponent } from './savedraftinvoice/savedraftinvoice.component';
import { SavedraftidebitnoteComponent } from './savedraftidebitnote/savedraftidebitnote.component';
import { PaymentreceiptComponent } from './paymentreceipt/paymentreceipt.component';
import { ReceiptreportComponent } from './receiptreport/receiptreport.component';
import { TemplatemasterComponent } from './templatemaster/templatemaster.component';


const routes: Routes = [
  { path: '', pathMatch: "full", redirectTo: 'auth' },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(mod => mod.AuthModule) },
  {
    path: 'layout', component: LayoutComponent,
    children: [
      {path: '', component:DashboardComponent},
      {path:'companymaster',component:CompanymasterComponent},
      {path:'branchmaster',component:BranchmasterComponent},
      {path:'employeemaster',component:EmployeemasterComponent},
      {path:'servicemaster',component:ServicemasterComponent},
      {path:'categorymaster',component:CategorymasterComponent},
      {path:'customermaster',component:CustomermasterComponent},
      {path:'invoicemaster',component:InvoicemasterComponent},
      {path:'adminsetting',component:AdminsettingComponent},
      {path:'estimate',component:EstimateComponent},
      {path:'invoice',component:InvoiceComponent},
      {path:'debitnote',component:DebitnoteComponent},
      {path:'invoicereport',component:InvoicereportComponent},
      {path:'savedraft',component:SavedraftComponent},
      {path:'savedraftestimate',component:SavedraftestimateComponent},
      {path:'savedraftinvoice',component:SavedraftinvoiceComponent},
      {path:'savedraftdebit',component:SavedraftidebitnoteComponent},
      {path:'paymentreceipt',component:PaymentreceiptComponent},
      {path:'receiptreport',component:ReceiptreportComponent},
      {path:'templatemaster',component:TemplatemasterComponent}
      
   
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
