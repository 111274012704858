import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator, ThemePalette } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { EmailTemplateMaster, employeeMaster, InvoiceFilterReqDtoMaster, JasperReport, SendMailMaster } from '../shared/AllModel';
import { activeEmailTemplateDetailsURL, getActiveBranchMasterURL, getActiveCustomerMasterURL, getActiveServiceMasterURL, getAllActiveEmployeeMasterURL, getDetailsByInvoiceMaster, getListByCompanyIdURL, invoiceMasterReportURL, sendEmailByInvoiceIdURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as xlsx from 'xlsx';


@Component({
  selector: 'app-invoicereport',
  templateUrl: './invoicereport.component.html',
  styleUrls: ['./invoicereport.component.css']
})
export class InvoicereportComponent implements OnInit {
  keyword = 'branchName';
  keyword1 = 'empName';
  keyword2 = 'customerName';
  keyword3 = 'serviceName';
  keyword4 = 'templateHeading';

  hideFlag: boolean = false;


  invoiceReportMasterForm: FormGroup;
  invoiceReportMasterModel = new InvoiceFilterReqDtoMaster();
  ActiveBranchList: any;
  ActiveClientList: any;
  ActiveEmployeeList: any;
  ActiveServiceList: any;
  ActiveTemplateList: Array<EmailTemplateMaster>;
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  textColorMobile: string;
  mobflag: boolean;
  list: any = [];
  role: any;
  roleFlag: boolean = true;
  editFlag: boolean = false;
  jasperReport = new JasperReport();
  sendEmailModel = new SendMailMaster();
  // templateId2:number;
  selectedAreas: string[];
  selectedAreas1: string[];
  selectedAreas2: string[];
  selectedAreas3: string[];
  selectedAreas4: string[];




  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Name', 'governmentFees', 'professionalFees', 'Description', 'invoice', 'number', 'amt', 'Action',];
  dataSource = new MatTableDataSource<InvoiceFilterReqDtoMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  @ViewChild('picker', { static: false }) picker: any;
  @ViewChild('picker1', { static: false }) picker1: any;



  ngOnInit() {
    this.invoiceReportMasterForm = this.formBuilder.group({
      branchId: new FormControl('', [Validators.required,]),
      empId: new FormControl('', [Validators.required,]),
      customerId: new FormControl('', [Validators.required,]),
      serviceId: new FormControl('', [Validators.required,]),
      fromDate: new FormControl('', [Validators.required]),
      toDate: new FormControl('', [Validators.required,]),
      type: new FormControl('', [Validators.required,]),
      templateId: new FormControl('', [Validators.required,]),
    })

    this.role = sessionStorage.getItem("role");

    if (this.role == "Admin") {
      this.roleFlag = true;

    }
    else {
      this.roleFlag = false;

    }

    this.getActiveTemplate();
    this.getActiveBranch();
    this.getActiveEmployee();
    this.getActiveCustomer();
    this.getActiveService();
    this.saveinvoicereport();

  }
  getActiveBranch() {
    this.httpService.getRequest(getActiveBranchMasterURL).subscribe((data: any) => {
      this.selectedAreas = data;
    })
  }
  getActiveTemplate() {
    this.httpService.getRequest(activeEmailTemplateDetailsURL).subscribe((data: any) => {
      this.ActiveTemplateList = data;

    })
  }

  search(query: string) {
    console.log('query', query)
    let result = this.select(query)
    this.selectedAreas = result;
  }

  select(query: string): string[] {
    let result: string[] = [];
    for (let branch of this.ActiveBranchList) {
      if (branch.branchName.toLowerCase().indexOf(query) > -1) {
        result.push(branch)
      }
    }
    return result
  }

  search1(query: string) {
    console.log('query', query)
    let result1 = this.select1(query)
    this.selectedAreas1 = result1;
  }

  select1(query: string): string[] {
    let result1: string[] = [];
    for (let emp of this.ActiveEmployeeList) {
      if (emp.empName.toLowerCase().indexOf(query) > -1) {
        result1.push(emp)
      }
    }
    return result1
  }

  search2(query: string) {
    console.log('query', query)
    let result2 = this.select2(query)
    this.selectedAreas2 = result2;
  }

  select2(query: string): string[] {
    let result2: string[] = [];
    for (let client of this.ActiveClientList) {
      if (client.customerName.toLowerCase().indexOf(query) > -1) {
        result2.push(client)
      }
    }
    return result2
  }

  search3(query: string) {
    console.log('query', query)
    let result3 = this.select3(query)
    this.selectedAreas3 = result3;
  }

  select3(query: string): string[] {
    let result3: string[] = [];
    for (let ser of this.ActiveServiceList) {
      if (ser.serviceName.toLowerCase().indexOf(query) > -1) {
        result3.push(ser)
      }
    }
    return result3
  }






  getActiveEmployee() {
    this.httpService.getRequest(getAllActiveEmployeeMasterURL).subscribe((data: any) => {
      this.selectedAreas1 = data;
    })
  }

  getActiveCustomer() {
    this.httpService.getRequest(getActiveCustomerMasterURL).subscribe((data: any) => {
      this.selectedAreas2 = data;
    })
  }

  getActiveService() {
    this.httpService.getRequest(getActiveServiceMasterURL).subscribe((data: any) => {
      this.selectedAreas3 = data;
    })
  }

  saveinvoicereport() {

    this.httpService.postRequest(invoiceMasterReportURL, this.invoiceReportMasterModel).subscribe((data: any) => {
      this.list = data;

      this.dataSource = new MatTableDataSource(this.list);
      this.dataSource.paginator = this.paginator;
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editInvoice(element) {
    sessionStorage.setItem("editFlag", 'true')
    sessionStorage.setItem("AddFlag", "false")

    sessionStorage.setItem("invoiceId", element.invoiceId);

    if (element.invoiceType == "PRO FORMA INVOICE") {
      this.router.navigateByUrl("/layout/invoicemaster")
    }
    if (element.invoiceType == "INVOICE") {
      this.router.navigateByUrl("/layout/invoice")
    }
    if (element.invoiceType == "ESTIMATE") {
      this.router.navigateByUrl("/layout/estimate")
    }
    if (element.invoiceType == "DEBIT NOTE") {
      this.router.navigateByUrl("/layout/debitnote")
    }


  }

  exportToExcel() {
    const ws: xlsx.WorkSheet =
      xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Invoice Report.xlsx');
  }


  orderInvoice(invoiceId) {

    this.jasperReport.invoiceId = invoiceId;
    let newWindow = window.open();
    this.httpService.postRequest(getDetailsByInvoiceMaster, this.jasperReport).subscribe((response: any) => {
      newWindow.location.href = response.url;
    })

  }

  editMail(invoiceId) {

    this.sendEmailModel.invoiceId = invoiceId;
  }

  sendMail() {
    this.httpService.postRequest(sendEmailByInvoiceIdURL, this.sendEmailModel).subscribe((data: any) => {
      if (data) {
        this.isEdit = true;
        this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });

      }
      else {
        this.toastr.errorToastr("Something went Wrong.", "Error");
      }
    })
  }
}
