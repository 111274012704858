import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { adminSetting, companeyMaster } from '../shared/AllModel';
import { createAdminSettingURL, updateAdminSettingURL, getAllAdminSettingURL, getByIdAdminSettingURL, getByIdCompaneyMasterURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-adminsetting',
  templateUrl: './adminsetting.component.html',
  styleUrls: ['./adminsetting.component.css']
})
export class AdminsettingComponent implements OnInit {

  adminSettingForm: FormGroup;
  adminSettingModel = new adminSetting();
  isEdit: boolean = true;
  hide = true;
  companeyMasterModel = new companeyMaster();


  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'name', 'pan', 'bname','bank','branch','account','ifsc', 'micr', 'Action'];
  dataSource = new MatTableDataSource<adminSetting>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.adminSettingForm = this.formBuilder.group({
      checkPayableName: new FormControl('', [Validators.required,]),
      panNo: new FormControl('', [Validators.required,]),
      beneficiaryName:new FormControl('', [Validators.required,]),
      contactPersonName: new FormControl('', [Validators.required,]),
      bankName: new FormControl('', [Validators.required,]),
      branchName: new FormControl('', [Validators.required,]),
      accountNo:new FormControl('', [Validators.required,]),
      ifscCode: new FormControl('', [Validators.required,]),
      micr: new FormControl('', [Validators.required,]),
      compName: new FormControl('', [Validators.required,]),
      mobNo1: new FormControl('', [Validators.required,]),
      mobNo2:new FormControl('', [Validators.required,]),
      contactPersonName1: new FormControl('', [Validators.required,]),
      emailId: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      address: new FormControl('', [Validators.required,]),
      compDesc:new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required,]),

      
    })
    this.getAllAdmin();
    this.getCompaneyById();
  }
  SaveAdmin() {
    if (this.isEdit) {

      this.httpService.postRequest(createAdminSettingURL, this.adminSettingModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllAdmin();
          this.adminSettingForm.reset();


        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
    }
    else {
      this.httpService.putRequest(updateAdminSettingURL, this.adminSettingModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllAdmin();
          this.adminSettingForm.reset();

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }

  getAllAdmin() {
    this.httpService.getRequest(getAllAdminSettingURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  getAdminById(adminId) {
    this.httpService.getRequest(getByIdAdminSettingURL + "/" + adminId).subscribe((data: any) => {
      this.adminSettingModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  getCompaneyById() {
    this.httpService.getRequest(getByIdCompaneyMasterURL + "/" + sessionStorage.getItem("compId")).subscribe((data: any) => {
      this.companeyMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    
    })
  }
}
