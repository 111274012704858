//////company master//////
export const createCompaneyMasterURL="/company_master";
export const updateConpaneyMasterURL="/company_master";
export const getAllCompaneyMasterURL="/company_master";
export const getByIdCompaneyMasterURL="/company_master/getCompanyDetailsByCompanyId";
export const getActiveCompaneyMasterURL="/company_master/activeCompanyDetails";
export const getCompanyDetailsByCompanyId="/company_master/getCompanyDetailsByCompanyId";
export const fileUpload="/uploadfile";


/////////branch master////////
export const createBranchMasterURL="/branch_master";
export const updateBranchMasterURL="/branch_master";
export const getAllBranchMasterURL="/branch_master";
export const getByIdBranchMasterURL="/branch_master/getByBranchId";
export const getActiveBranchMasterURL="/branch_master/activeBranchDetails";
export const getByEditIdURL="/branch_master/editByBranchId";


//////customer master////////
export const createCustomerMasterURL="/customer_master";
export const updateCustomerMasterURL="/customer_master";
export const getAllCustomerMasterURL="/customer_master";
export const getByIdCustomerMasterURL="/customer_master/getByCustomerId";
export const getActiveCustomerMasterURL="/customer_master/activeCustomerDetails";
export const uploadClientExcelURL="/customer_master/uploadClientExcel";

//////employee master///////
export const createEmployeeMasterURL="/employee_master";
export const updateEmployeeMasterURL="/employee_master";
export const getAllEmployeeMasterURL="/employee_master";
export const getByIdEmployeeMasterURL="/employee_master/getByEmployeeId";
export const getAllActiveEmployeeMasterURL="/employee_master/activeEmployee";
export const getListByCompanyIdURL="/branch_master/getListByCompanyId";
export const checkEmployeeMobileNo="/employee_master/checkEmpMobileNo";
export const upLoadEmployeeExcelURL="/employee_master/uploadEmployeeExcel";

//////service master///////
export const createServiceMasterURL="/serviceMaster";
export const updateServiceMasterURL="/serviceMaster";
export const getAllServiceMasterURL="/serviceMaster/getAllService";
export const getByIdServiceMasterURL="/serviceMaster/editService";
export const getActiveServiceMasterURL="/serviceMaster/getActiveService";
export const getServiceListByCategoryId="/serviceMaster/getListByCategoryId";
export const importExcelURL="/serviceMaster/uploadServiceExcel";

/////category master/////////
export const createCategoryMasterURL="/categoryMaster";
export const updateCategoryMasterURL="/categoryMaster";
export const getAllCategoryMasterURL="/categoryMaster/getAllCategory";
export const getByIdCategoryMasterURL="/categoryMaster/editCategory";
export const getActiveCategoryMasterURL="/categoryMaster/getActiveCategory";
export const importToExcelByCompIdURL="/categoryMaster/uploadCategoryExcel";

/////invoice master////
export const createInvoiceMasterURL="/invoiceMaster";
export const customerMobileWiseSearchURL="/invoiceMaster/customerMobileNoWiseSearch";
export const serviceNameWiseSearchURL="/invoiceMaster/serviceNameWiseSearch";
export const getDetailsByInvoiceMaster="/invoiceMaster/getReportInvoiceId";
export const getMaxInvoiceId="/invoiceMaster/getMaxInvoiceId";
export const getSaveAsDraftListURL="/invoiceMaster/getSaveAsDraftWiseList";
export const getByInvoiceId="/invoiceMaster/getInvoiceDetailsListByInvoiceId";
export const editByInvoiceId="/invoiceMaster/getByInvoiceIdEdit";


//////admin setting//////
export const createAdminSettingURL="/admin_master";
export const updateAdminSettingURL="/admin_master";
export const getByIdAdminSettingURL="/admin_master/getAdminDetailsByAdminId";
export const getAllAdminSettingURL="/admin_master/getAllAdmin";

/////login master////
export const forgotPassowrdURL="/login_master/forgotPassword";
export const loginMasterURL="/login_master/login";


/////////////reports///////////
export const invoiceMasterReportURL="/invoiceMaster/getInvoiceReport";
export const getPdfReportURL="/report/pdf/getpdf";
export const pdfReportURL="/report/pdfReport";
export const clientWiseReceiptReportURL="/clientwiseReceipt/getReceiptReport";

//////////dashboard///////////
export const getDailyCountURL="/dashboard/getDailyCountOfInvoices";
export const getWeeklyCountURL="/dashboard/getWeeklyCountOfInvoices";
export const getMonthlyCountURL="/dashboard/getMonthlyCountOfInvoices";
export const getYearlyCountURL="/dashboard/getYearlyCountOfInvoices"
export const getWeeklyDayCountURL="/dashboard/getWeeklyDayCountOfInvoices";

/////////payment receipt///////
export const createClientWiseReceiptURL="/clientwiseReceipt";
export const clientWiseInvoiceNoURL="/clientwiseReceipt/invoiceNowiseSearch";
export const clientWiseReceiptPdfURL="/clientwiseReceipt/PDFLink";
export const testReportURL="/invoiceMaster/test";
export const getClientWiseReceiptId="/clientwiseReceipt/getReceiptById";

//////email template master//////
export const getAllEmailTemplateMasterDetailsURL="/email_template_master";
export const createEmailTemplateMasterURL="/email_template_master";
export const updateEmailTemplateMasterURL="/email_template_master";
export const activeEmailTemplateDetailsURL="/email_template_master/activeEmailTemplateDetails";
export const getEmailTemplateDetailsByTemplateEmailIdURL="/email_template_master/getEmailTemplateDetailsByEmailTemplateId";
export const sendEmailByInvoiceIdURL="/email_template_master/sendEmailByInvoiceId";

export const sendEmailByReceiptMastarURL="/email_template_master/sendEmailByReceiptId";

/////////fees/////////
export const getByListOtherThanSmallEntityURL ="/serviceMaster/getByListOtherThanSmallEntity";
export const getByListNaturalPersonURL="/serviceMaster/getByListNaturalPerson";
export const getByListSmallEntityURL="/serviceMaster/getByListEntityFees";
export const getEntityNameWiseCategoryListURL="/serviceMaster/getListByCategoryIdAndEntityNameWiseList";