export const MainURL = {
  //invoice generation server///////
  HostUrl: 'http://148.72.209.230:1183',


  // HostUrl: 'http://192.168.0.26:1183',

  imageUrl: '/assets'
}


