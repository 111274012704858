import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { customerMaster, EmailTemplateMaster, employeeMaster, JasperReport, MailMaster, ReceiptReportMaster, SendMailMaster } from '../shared/AllModel';
import { activeEmailTemplateDetailsURL, clientWiseReceiptPdfURL, clientWiseReceiptReportURL, getActiveCustomerMasterURL, getAllActiveEmployeeMasterURL, getClientWiseReceiptId, sendEmailByReceiptMastarURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-receiptreport',
  templateUrl: './receiptreport.component.html',
  styleUrls: ['./receiptreport.component.css']
})
export class ReceiptreportComponent implements OnInit {

keyword = "customerName";
keyword1 = "empName";
  receiptReportMasterForm: FormGroup;
  receiptReportMasterModel = new ReceiptReportMaster();
  ActiveTemplateList:Array<EmailTemplateMaster>;

  ActiveEmployeeList:any;
  ActiveClientList:any;
  jasperReport = new JasperReport();
  EmailModel= new MailMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg:string;
  textColorMobile:string;
  mobflag:boolean;
  ReportList: any=[];
  selectedAreas2:string[];
  selectedAreas1:string[];
  role: any;
  roleFlag: boolean = true;





  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.','date','cname','ename','type','number', 'final', 'paid','Action'];
  dataSource = new MatTableDataSource<ReceiptReportMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.receiptReportMasterForm = this.formBuilder.group({
      empId: new FormControl('', [Validators.required,]),
      customerId: new FormControl('', [Validators.required,]),
      templateId:new FormControl('', [Validators.required,]),
      
    })
    this.getActiveClient();
    this.getActiveEmployee();
    this.getReport();
    this.getActiveTemplate();


    this.role = sessionStorage.getItem("role");

    if (this.role == "Admin") {
      this.roleFlag = true;
    
    }
    else {
     this.roleFlag = false;
    
    } 
    }
  

  getActiveClient() {
    this.httpService.getRequest(getActiveCustomerMasterURL).subscribe((data: any) => {
      this.selectedAreas2 = data;
    })
  }

  search2(query: string){
    console.log('query', query)
    let result2 = this.select2(query)
    this.selectedAreas2 = result2;
  }

  select2(query: string):string[]{
    let result2: string[] = [];
    for(let client of this.ActiveClientList){
      if(client.customerName.toLowerCase().indexOf(query) > -1){
        result2.push(client)
      }
    }
    return result2
  }

  search1(query: string){
    console.log('query', query)
    let result1 = this.select1(query)
    this.selectedAreas1 = result1;
  }

  select1(query: string):string[]{
    let result1: string[] = [];
    for(let emp of this.ActiveEmployeeList){
      if(emp.empName.toLowerCase().indexOf(query) > -1){
        result1.push(emp)
      }
    }
    return result1
  }

  getActiveTemplate() {
    this.httpService.getRequest(activeEmailTemplateDetailsURL).subscribe((data: any) => {
      this.ActiveTemplateList = data;
      
    })
  }

  getActiveEmployee() {
    this.httpService.getRequest(getAllActiveEmployeeMasterURL).subscribe((data: any) => {
      this.selectedAreas1 = data;
    })
  }

  getReport(){
    this.httpService.postRequest(clientWiseReceiptReportURL, this.receiptReportMasterModel).subscribe((data: any) => {
        this.isEdit = true;
        this.ReportList=data;
        this.dataSource = new MatTableDataSource(this.ReportList);
        this.dataSource.paginator = this.paginator;
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }





  orderInvoice(clientwiseReceiptMasterId) {

    this.jasperReport.clientwiseReceiptMasterId = clientwiseReceiptMasterId;
    let newWindow = window.open();
    this.httpService.postRequest(clientWiseReceiptPdfURL, this.jasperReport).subscribe((response: any) => {
      newWindow.location.href = response.filePath;
    })

  }



  exportToExcel() {
    const ws: xlsx.WorkSheet =
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Receipt Report.xlsx');
    }

    editMail(clientwiseReceiptMasterId){

      this.EmailModel.clientwiseReceiptMasterId=clientwiseReceiptMasterId;
    }
  
    getById(element){
      sessionStorage.setItem("clientwiseReceiptMasterId", element.clientwiseReceiptMasterId);
      sessionStorage.setItem("editFlag",true.toString()) 
      this.router.navigateByUrl("/layout/paymentreceipt")
    }
    sendMail(){
      this.httpService.postRequest(sendEmailByReceiptMastarURL, this.EmailModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
  
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
    }
}
