import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { customerMaster } from '../shared/AllModel';
import { createCustomerMasterURL, getActiveBranchMasterURL, getAllCustomerMasterURL, getByIdCustomerMasterURL, importToExcelByCompIdURL, updateCustomerMasterURL, uploadClientExcelURL, } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-customermaster',
  templateUrl: './customermaster.component.html',
  styleUrls: ['./customermaster.component.css']
})
export class CustomermasterComponent implements OnInit {

  customerMasterForm: FormGroup;
  customerMasterModel = new customerMaster();
  ActiveBranchList: any;
  isEdit: boolean = true;
  hide = true;
  selectedAreas:string[];
  excelFile:any;




  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Branch Name','cus Name', 'gst', 'Mobile No', 'Email','pan', 'Status', 'Action'];
  dataSource = new MatTableDataSource<customerMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.customerMasterForm = this.formBuilder.group({
      customerName: new FormControl('', [Validators.required,Validators.pattern("^[a-zA-Z \-\']+")]),
      customerMobileNo:  new FormControl('', [Validators.required,]),
      emailId: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      customerAddress: new FormControl('', [Validators.required,]),
      gstNo: new FormControl('',),
      // pendingAmount: new FormControl('', [Validators.required,]),
      customerStatus: new FormControl('', [Validators.required,]),
      branchId: new FormControl('', [Validators.required,]),
      contactPerson: new FormControl('', [Validators.required,Validators.pattern("^[a-zA-Z \-\']+")]),
      contactPersonMobileNo:new FormControl('', [Validators.required,]),
      contactPersonEmail:new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      panNo:new FormControl('',[Validators.pattern("^[A-Za-z]{5}[0-9]{4}[A-Za-z]$")]),

    })
    this.getActiveBranch();
    this.getAllCustomer();
  }
  getActiveBranch() {
    this.httpService.getRequest(getActiveBranchMasterURL).subscribe((data: any) => {
      this.selectedAreas = data;
      // this.selectedAreas=this.ActiveBranchList;

    })
  }

  search(query: string){
    console.log('query', query)
    let result = this.select(query)
    this.selectedAreas = result;
  }

  select(query: string):string[]{
    let result: string[] = [];
    for(let branch of this.ActiveBranchList){
      if(branch.branchName.toLowerCase().indexOf(query) > -1){
        result.push(branch)
      }
    }
    return result
  }

  SaveCustomer() {
    if (this.isEdit) {

      this.httpService.postRequest(createCustomerMasterURL, this.customerMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllCustomer();
          this.customerMasterForm.reset();


        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
    }
    else {
      this.httpService.putRequest(updateCustomerMasterURL, this.customerMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllCustomer();
          this.customerMasterForm.reset();

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }
  getAllCustomer() {
    this.httpService.getRequest(getAllCustomerMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  getCustomerById(customerId) {
    this.httpService.getRequest(getByIdCustomerMasterURL + "/" + customerId).subscribe((data: any) => {
      this.customerMasterModel = data;
      this.customerMasterModel.branchId=data.branchMaster.branchId;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }

  filee(e) {
    console.log(e.target.files[0]);
    this.excelFile = e.target.files[0];
  }

  submitExcel() {
    let formData = new FormData();
    formData.append('branchId', String(this.customerMasterModel.branchId));
    formData.append('uploadexcel', this.excelFile);

    this.httpService.excelUpload(uploadClientExcelURL, formData).subscribe((data: any) => {
      this.toastr.successToastr("Save Succesfully", "Success!", { timeOut: 500 });
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  exportToExcel() {
    const ws: xlsx.WorkSheet =
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Client Details.xlsx');
    }
}
