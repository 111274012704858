import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { categoryMaster } from '../shared/AllModel';
import { createCategoryMasterURL, getActiveCompaneyMasterURL, getAllCategoryMasterURL, getByIdCategoryMasterURL, importToExcelByCompIdURL, updateCategoryMasterURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-categorymaster',
  templateUrl: './categorymaster.component.html',
  styleUrls: ['./categorymaster.component.css']
})
export class CategorymasterComponent implements OnInit {

  categoryMasterForm: FormGroup;
  categoryMasterModel = new categoryMaster();
  ActiveCompaneyList:any[];
  isEdit: boolean = true;
  hide = true;
  excelFile:any;


  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Name','des', 'Status', 'Action'];
  dataSource = new MatTableDataSource<categoryMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;


  ngOnInit() {
    this.categoryMasterForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required,]),
      description: new FormControl('', ),
      status: new FormControl('', [Validators.required,]),
      compId:new FormControl(''),
      compId1:new FormControl(''),

      
    })
    this.getAllCategory();
    this.getActiveCompaney();
    // this.categoryMasterModel.compId=Number(sessionStorage.getItem("compId"));
  }
  getActiveCompaney() {
    this.httpService.getRequest(getActiveCompaneyMasterURL).subscribe((data: any) => {
      this.ActiveCompaneyList = data;
      
    })
  }

  search(query: string) {
    console.log('query', query)
    let result = this.select(query)
    this.ActiveCompaneyList = result;
  }

  select(query: string): string[] {
    let result: string[] = [];
    for (let company of this.ActiveCompaneyList) {
      if (company.compName.toLowerCase().indexOf(query) > -1) {
        result.push(company)
      }
    }
    return result
  }

  
  filee(e) {
    console.log(e.target.files[0]);
    this.excelFile = e.target.files[0];
  }

  SaveCategory() {
    if (this.isEdit) {
      this.httpService.postRequest(createCategoryMasterURL, this.categoryMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllCategory();
          this.categoryMasterForm.reset();
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
    }
    else {
      this.httpService.putRequest(updateCategoryMasterURL, this.categoryMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllCategory();
          this.categoryMasterForm.reset();
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }

  submitExcel() {
    let formData = new FormData();
    formData.append('compId', String(this.categoryMasterModel.compId));
    formData.append('uploadexcel', this.excelFile);
    this.httpService.excelUpload(importToExcelByCompIdURL, formData).subscribe((data: any) => {
      this.toastr.successToastr("Save Succesfully", "Success!", { timeOut: 500 });
    });
  }

  getAllCategory() {
    this.httpService.getRequest(getAllCategoryMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  getCategoryById(categoryId) {
    this.httpService.getRequest(getByIdCategoryMasterURL + "/" + categoryId).subscribe((data: any) => {
      this.categoryMasterModel = data;
      // this.categoryMasterModel.branchId=data.branchMaster.branchId;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  exportToExcel() {
    const ws: xlsx.WorkSheet =
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Category Details.xlsx');
    }
}
