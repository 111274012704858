import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { getAllCompaneyMasterURL } from '../AllURL';
import { HttpmethodsService } from '../service/httpmethods.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  companyname:string;

  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private router: Router) { }

  ngOnInit() {
 
  }
  toggleSideBar() {
    this.toggleSideBarForMe.emit();
  }
  // toggleSideBarRight(){
  // this.toggleSideBarForMe.emit();

  // }

  logout() {

    sessionStorage.removeItem("empId");
    sessionStorage.removeItem("compId");
    this.router.navigateByUrl('/auth/employeelogin');
    // sessionStorage.removeItem("compId");

  }



}
