import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { saveDraftMaster } from '../shared/AllModel';
import { getSaveAsDraftListURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as xlsx from 'xlsx';


@Component({
  selector: 'app-savedraftinvoice',
  templateUrl: './savedraftinvoice.component.html',
  styleUrls: ['./savedraftinvoice.component.css']
})
export class SavedraftinvoiceComponent implements OnInit {

  saveDraftForm: FormGroup;
  saveDraftModel = new saveDraftMaster();
  isEdit: boolean = true;
  hide = true;
  saveDraftList: any = [];
  invoiceType: string;



  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }



  displayedColumns: string[] = ['Sr.No.', 'draft', 'type', 'date', 'Action'];
  dataSource = new MatTableDataSource<saveDraftMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;


  ngOnInit() {
    this.saveDraftForm = this.formBuilder.group({
      name: new FormControl('',),
      dateTime: new FormControl('',),
      finalAmt: new FormControl('',),
      invoiceNo: new FormControl('',),
      invoiceType: new FormControl('',),
      serviceName: new FormControl('',),


    })
    this.route.queryParams
    .subscribe(params => {
      console.log("gameeeee=" + params);
      


        if(this.saveDraftModel != null)
        {
          this.saveDraftModel.invoiceType = "INVOICE"
          this.saveDraftModel.saveAsDraft = true;

          this.httpService.postRequest(getSaveAsDraftListURL, this.saveDraftModel).subscribe((data: any) => {
            this.saveDraftList = data;
            this.dataSource = new MatTableDataSource(this.saveDraftList);
            this.dataSource.paginator = this.paginator;
          })
        }
        else{
          this.saveDraftModel.invoiceType = "Invoice"
          this.saveDraftModel.saveAsDraft = params.saveAsDraft;

          this.httpService.postRequest(getSaveAsDraftListURL, this.saveDraftModel).subscribe((data: any) => {
            this.saveDraftList = data;
            this.dataSource = new MatTableDataSource(this.saveDraftList);
            this.dataSource.paginator = this.paginator;
          })
        }
    
    }
    );
  }

  addNew(){
    this.router.navigateByUrl("/layout/invoice")
    sessionStorage.setItem("AddFlag", "true")
  }
 

  editInvoiceDraft(element) {

    this.router.navigate(['/layout/invoice'], {
      queryParams: {
        dateTime: element.dateTime,
        serviceName: element.serviceName,
        description: element.description,
        professionalFees: element.professionalFees,
        governmentFees: element.governmentFees,
        categoryId: element.categoryId,
        serviceId: element.serviceId,

      }

    });

    sessionStorage.setItem("invoiceId", element.invoiceId);
    sessionStorage.setItem("editFlag",false.toString()) 
  }

  exportToExcel() {
    const ws: xlsx.WorkSheet =
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Invoice Details.xlsx');
    }

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
}
