import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { companeyMaster } from '../shared/AllModel';
import {  createCompaneyMasterURL, fileUpload, getAllCompaneyMasterURL, getByIdCompaneyMasterURL, updateConpaneyMasterURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-companymaster',
  templateUrl: './companymaster.component.html',
  styleUrls: ['./companymaster.component.css']
})
export class CompanymasterComponent implements OnInit {
  companeyMasterForm: FormGroup;
  companeyMasterModel = new companeyMaster();
  isEdit: boolean = true;
  hide = true;
  filetoUpload: File;
  


  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Companey Name','website', 'Mobile No.1','Email','gst', 'Status', 'Action'];
  dataSource = new MatTableDataSource<companeyMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.companeyMasterForm = this.formBuilder.group({
      compName: new FormControl('', [Validators.required,Validators.pattern("^[a-zA-Z \-\']+")]),
      mobNo1: new FormControl('', [Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$") ]),
      mobNo2:new FormControl('',[Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      contactPersonName: new FormControl('', [Validators.required,Validators.pattern("^[a-zA-Z \-\']+")]),
      emailId: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      address: new FormControl('', [Validators.required,]),
      compDesc:new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required,]),
      url:new FormControl('', [Validators.pattern(reg)]),
      /////admin setting///
      checkPayableName: new FormControl('', [Validators.required,Validators.pattern("^[a-zA-Z \-\']+")]),
      panNo: new FormControl('', [Validators.required,Validators.pattern("^[A-Za-z]{5}[0-9]{4}[A-Za-z]$")]),
      beneficiaryName:new FormControl('', [Validators.required,Validators.pattern("^[a-zA-Z \-\']+")]),
      bankName: new FormControl('', [Validators.required,Validators.pattern("^[a-zA-Z \-\']+")]),
      branchName: new FormControl('', [Validators.required,Validators.pattern("^[a-zA-Z \-\']+")]),
      accountNo:new FormControl('', [Validators.required,]),
      ifscCode: new FormControl('', [Validators.required,Validators.pattern("^[A-Z]{4}0[A-Z0-9]{6}$")]),
      micr: new FormControl('', [Validators.required,Validators.minLength(9)]),
      gstNo:new FormControl('',),
      logo:new FormControl('', [Validators.required,]),
      swistCode:new FormControl('',),
      
    })
    this.getAllCompaney();
  }
  get f() { return this.companeyMasterForm.controls; }
  SaveCompaney() {
    if (this.isEdit) {
     
        this.httpService.postRequest(createCompaneyMasterURL, this.companeyMasterModel).subscribe((data: any) => {
          if (data) {
            this.isEdit = true;
            this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
            this.getAllCompaney();
            this.companeyMasterForm.reset();
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })
    }
    else {
    
      this.httpService.putRequest(updateConpaneyMasterURL, this.companeyMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllCompaney();
          this.companeyMasterForm.reset();
          this.companeyMasterModel = new companeyMaster();
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }
  getAllCompaney() {
    this.httpService.getRequest(getAllCompaneyMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  getCompaneyById(compId) {
    this.httpService.getRequest(getByIdCompaneyMasterURL + "/" + compId).subscribe((data: any) => {
      this.companeyMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  handleFileInput(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.companeyMasterModel.logo = data.path;
        
      }
      else {
        this.toastr.errorToastr(data.path, 'error!', { timeout: 500 });
      }
    })
  }

  exportToExcel() {
    const ws: xlsx.WorkSheet =
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Company Details.xlsx');
    }
}
