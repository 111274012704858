import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { branchMaster } from '../shared/AllModel';
import { createBranchMasterURL, getActiveCompaneyMasterURL, getAllBranchMasterURL, getByEditIdURL, getByIdBranchMasterURL, updateBranchMasterURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as xlsx from 'xlsx';
import { Observable } from 'rxjs';
import { map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-branchmaster',
  templateUrl: './branchmaster.component.html',
  styleUrls: ['./branchmaster.component.css']
})
export class BranchmasterComponent implements OnInit {

  keyword = 'compName';


  branchMasterForm: FormGroup;
  branchMasterModel = new branchMaster();
  // ActiveCompaneyList: Array<branchMaster>;
  ActiveCompaneyList: any[];
  selectedAreas:string[];

  isEdit: boolean = true;
  hide = true;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Branch Name','Branch Code', 'Mobile No','Email','Description', 'Status', 'Action'];
  dataSource = new MatTableDataSource<branchMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.branchMasterForm = this.formBuilder.group({
      branchName: new FormControl('', [Validators.required,Validators.pattern("^[a-zA-Z \-\']+")]),
      branchMobNo: new FormControl('', [Validators.required,]),
      contactName: new FormControl('', [Validators.required,Validators.pattern("^[a-zA-Z \-\']+")]),
      branchEmailId: new FormControl('', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      branchAdd: new FormControl('', [Validators.required,]),
      branchDes:new FormControl('', [Validators.required,]),
      password: new FormControl('', [Validators.required,]),
      branchStatus: new FormControl('', [Validators.required,]), 
      branchCode: new FormControl('', [Validators.required,]), 
      compId:new FormControl('', [Validators.required,]), 

    })
    this.getActiveCompaney();
    this.getAllBranch();

  }



  getActiveCompaney() {
    this.httpService.getRequest(getActiveCompaneyMasterURL).subscribe((data: any) => {
      this.selectedAreas = data;
     
    })
  }



  SaveBranch() {
    if (this.isEdit) {
      this.httpService.postRequest(createBranchMasterURL, this.branchMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllBranch();
          this.branchMasterForm.reset();
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
    }
    else {
      this.httpService.putRequest(updateBranchMasterURL, this.branchMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllBranch();
          this.branchMasterForm.reset();
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }

  getAllBranch() {
    this.httpService.getRequest(getAllBranchMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  search(query: string){
    console.log('query', query)
    let result = this.select(query)
    this.selectedAreas = result;
  }

  select(query: string):string[]{
    let result: string[] = [];
    for(let company of this.ActiveCompaneyList){
      if(company.compName.toLowerCase().indexOf(query) > -1){
        result.push(company)
      }
    }
    return result
  }

  getBranchById(branchId) {
    this.httpService.getRequest(getByEditIdURL + "/" + branchId).subscribe((data: any) => {
      this.branchMasterModel = data;
      this.branchMasterModel.compId=data.companyMaster.compId;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  exportToExcel() {
    const ws: xlsx.WorkSheet =
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Branch Details.xlsx');
    }
}
