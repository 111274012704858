import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proformainvoice',
  templateUrl: './proformainvoice.component.html',
  styleUrls: ['./proformainvoice.component.css']
})
export class ProformainvoiceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
