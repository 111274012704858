import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Label } from 'ng2-charts';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DashboardMaster } from '../shared/AllModel';
import { getDailyCountURL, getMonthlyCountURL, getWeeklyCountURL, getWeeklyDayCountURL, getYearlyCountURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
// import Chart from 'chart.js';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  dashboardModel= new DashboardMaster();
  dailyCountOfInvoices:number;
  weeklyCountOfInvoices:number;
  monthlyCountOfInvoices:number;
  yearlyCountOfInvoices:number;

  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  public canvas: any;
  public ctx;
  public chartColor;
  public chartEmail;
  public chartHours;

  public arbarChartLabels: Label[] = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  public arbarChartType = 'bar';
  public arbarChartOptions: any = { legend: { display: true, labels: { fontColor: 'black' } } };
  public barChartData: any[] = [];


  ngOnInit() {

    this.getDailyCount();
    this.getWeeklyCount();
    this.getMonthlyCount();
    this.getYearlyCount();
    this.getWeeklyDayCount();
  }

  ////daily count//////
  getDailyCount(){
    this.httpService.getRequest(getDailyCountURL).subscribe((data:any)=>{
      this.dailyCountOfInvoices=data.dailyCountOfInvoices;
    })
  }

  ////weekly count////////
  getWeeklyCount(){
    this.httpService.getRequest(getWeeklyCountURL).subscribe((data:any)=>{
      this.weeklyCountOfInvoices=data.weeklyCountOfInvoices;
    })
  }

    ////monthly count////////
    getMonthlyCount(){
      this.httpService.getRequest(getMonthlyCountURL).subscribe((data:any)=>{
        this.monthlyCountOfInvoices=data.monthlyCountOfInvoices;
      })
    }

    ////yearly count////////
    getYearlyCount(){
      this.httpService.getRequest(getYearlyCountURL).subscribe((data:any)=>{
        this.yearlyCountOfInvoices=data.yearlyCountOfInvoices;
      })
    }

    getWeeklyDayCount() {
      this.httpService.getRequest(getWeeklyDayCountURL).subscribe((data: any) => {
        this.barChartData = [{ data: data.dayCountList, label: 'Day Count' }]
   
      })
    }
}
