import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatPaginatorModule, MatSidenavModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatAutocompleteModule, MatSlideToggleModule, MatDatepickerModule, MatProgressSpinnerModule, MatNativeDateModule, MatRadioModule, MatCheckboxModule, MatLabel, MatTableModule } from '@angular/material';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CommonModule, DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatDialogModule, MatIconModule, MatDividerModule, MatListModule, MatToolbarModule, MatMenuModule, MatCardModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HttpClientModule } from '@angular/common/http'; 
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import * as $ from 'jquery';
import { CompanymasterComponent } from './companymaster/companymaster.component';
import { BranchmasterComponent } from './branchmaster/branchmaster.component';
import { EmployeemasterComponent } from './employeemaster/employeemaster.component';
import { ServicemasterComponent } from './servicemaster/servicemaster.component';
import { CategorymasterComponent } from './categorymaster/categorymaster.component';
import { CustomermasterComponent } from './customermaster/customermaster.component';
import { InvoicemasterComponent } from './invoicemaster/invoicemaster.component';
import { AdminsettingComponent } from './adminsetting/adminsetting.component';
import { ProformainvoiceComponent } from './proformainvoice/proformainvoice.component';
import { EstimateComponent } from './estimate/estimate.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { DebitnoteComponent } from './debitnote/debitnote.component';
import { InvoicereportComponent } from './invoicereport/invoicereport.component';
import { SavedraftComponent } from './savedraft/savedraft.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { SavedraftestimateComponent } from './savedraftestimate/savedraftestimate.component';
import { SavedraftinvoiceComponent } from './savedraftinvoice/savedraftinvoice.component';
import { SavedraftidebitnoteComponent } from './savedraftidebitnote/savedraftidebitnote.component';
import { PaymentreceiptComponent } from './paymentreceipt/paymentreceipt.component';
import { ReceiptreportComponent } from './receiptreport/receiptreport.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { MatSelectFilterModule } from 'mat-select-filter';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TemplatemasterComponent } from './templatemaster/templatemaster.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {MatTooltipModule} from '@angular/material/tooltip';


// import {NgxMatDatetimePickerModule} from 'ngx-mat-datetime-picker'





@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    DashboardComponent,
    CompanymasterComponent,
    BranchmasterComponent,
    EmployeemasterComponent,
    ServicemasterComponent,
    CategorymasterComponent,
    CustomermasterComponent,
    InvoicemasterComponent,
    AdminsettingComponent,
    ProformainvoiceComponent,
    EstimateComponent,
    InvoiceComponent,
    DebitnoteComponent,
    InvoicereportComponent,
    SavedraftComponent,
    SavedraftestimateComponent,
    SavedraftinvoiceComponent,
    SavedraftidebitnoteComponent,
    PaymentreceiptComponent,
    ReceiptreportComponent,
    TemplatemasterComponent,

     
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    MatButtonModule, MatDialogModule,MatIconModule,MatDividerModule,MatListModule,MatToolbarModule,
    MatMenuModule,MatCardModule, MatPaginatorModule,
    CommonModule,
    RouterModule, 
    SharedModule,
    MatSidenavModule,
    MatDividerModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDialogModule, 
    MatIconModule,
    MatSidenavModule,
    MatDividerModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatPaginatorModule,
    HttpClientModule,
    MatTableModule,
    ChartsModule,
    NgMultiSelectDropDownModule,
    AngularEditorModule ,
    BsDatepickerModule.forRoot(),
    AutocompleteLibModule
    
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},DatePipe,ThemeService],
  exports: [
    MatButtonModule, MatDialogModule, MatIconModule],
    
  bootstrap: [AppComponent],
  entryComponents: [],

})
export class AppModule { }
