import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { branchMaster, employeeMaster } from '../shared/AllModel';
import { checkEmployeeMobileNo, createEmployeeMasterURL, getActiveBranchMasterURL, getActiveCompaneyMasterURL, getAllEmployeeMasterURL, getByIdEmployeeMasterURL, getListByCompanyIdURL, importToExcelByCompIdURL, updateEmployeeMasterURL, upLoadEmployeeExcelURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-employeemaster',
  templateUrl: './employeemaster.component.html',
  styleUrls: ['./employeemaster.component.css']
})
export class EmployeemasterComponent implements OnInit {
  keyword = 'compName';
  keyword1 = 'branchName';
  employeeMasterForm: FormGroup;
  employeeMasterModel = new employeeMaster();
  ActiveBranchList: any[];
  ActiveCompaneyList: any[];
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  textColorMobile: string;
  mobflag: boolean;
  branchList:any;
  selectedAreas:string[];
  selectedAreas1:string[];
  excelFile:any;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Branch Name', 'role', 'Employee Name', 'Mobile No', 'Email', 'Address', 'Status', 'Action'];
  dataSource = new MatTableDataSource<employeeMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;


  ngOnInit() {
    this.employeeMasterForm = this.formBuilder.group({
      empName: new FormControl('', [Validators.required,Validators.pattern("^[a-zA-Z \-\']+")]),
      empMob: new FormControl('', [Validators.required,Validators.minLength(10)]),
      empEmailId: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      empAdd: new FormControl('', [Validators.required,]),
      password: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required,]),
      branchId: new FormControl('', [Validators.required,]),
      employeeId: new FormControl('', [Validators.required,]),
      role: new FormControl('', [Validators.required,]),
      compId:new FormControl('', [Validators.required,]),

    })
    this.getActiveBranch();
    this.getAllEmployee();
    this.getActiveCompaney();

  }
  getActiveBranch() {
    this.httpService.getRequest(getActiveBranchMasterURL).subscribe((data: any) => {
      this.ActiveBranchList = data;
      this.selectedAreas1 = this.ActiveBranchList;
    })
  }

  getActiveCompaney() {
    this.httpService.getRequest(getActiveCompaneyMasterURL).subscribe((data: any) => {
      this.ActiveCompaneyList = data;
      this.selectedAreas=this.ActiveCompaneyList;
    })
  }

  search(query: string){
    console.log('query', query)
    let result = this.select(query)
    this.selectedAreas = result;
  }

  select(query: string):string[]{
    let result: string[] = [];
    for(let company of this.ActiveCompaneyList){
      if(company.compName.toLowerCase().indexOf(query) > -1){
        result.push(company)
      }
    }
    return result
  }

  search1(query: string){
    console.log('query', query)
    let result1 = this.select1(query)
    this.selectedAreas1 = result1;
  }

  select1(query: string):string[]{
    let result1: string[] = [];
    for(let branch of this.branchList){
      if(branch.branchName.toLowerCase().indexOf(query) > -1){
        result1.push(branch)
      }
    
    }
   

    return result1
  }




  
  getBranchList(e) {
    this.httpService.getRequest(getListByCompanyIdURL + "/" + e.value).subscribe((data: any) => {
      this.selectedAreas1 = data;
    })
  }
  

  SaveEmployee() {
    if (this.isEdit) {
    
      this.httpService.postRequest(createEmployeeMasterURL, this.employeeMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.mobileMsg = "";
          this.getAllEmployee();
          this.employeeMasterForm.reset();


        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
    }
    else {
      this.httpService.putRequest(updateEmployeeMasterURL, this.employeeMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.mobileMsg = "";
          this.getAllEmployee();
          this.employeeMasterForm.reset();

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }

  getAllEmployee() {
    this.httpService.getRequest(getAllEmployeeMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  getEmployeeById(empId) {
    this.httpService.getRequest(getByIdEmployeeMasterURL + "/" + empId).subscribe((data: any) => {
      this.employeeMasterModel = data;
      this.employeeMasterModel.branchId = data.branchMaster.branchId;
      this.employeeMasterModel.compId = data.companyMaster.compId;
      // this.employeeMasterModel.compId=data.companymaster.compId;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }

  checkEmployeeMobileNo() {
    this.httpService.getRequest(checkEmployeeMobileNo + "/" + this.employeeMasterModel.empMob).subscribe((data: any) => {
      if (data.flag) {
        this.mobileMsg = data.message;
        this.textColorMobile = "Red";
        this.mobflag = false;
      }
      else {
        // this.mobileMsg = data.message;
        // this.textColorMobile = "green";
        // this.mobflag = true;
      }
    })
  }
  filee(e) {
    console.log(e.target.files[0]);
    this.excelFile = e.target.files[0];
    
  }

  submitExcel() {
    let formData = new FormData();
    formData.append('compId', String(this.employeeMasterModel.compId));
    formData.append('branchId', String(this.employeeMasterModel.branchId));

    formData.append('uploadexcel', this.excelFile);

    this.httpService.excelUpload(upLoadEmployeeExcelURL, formData).subscribe((data: any) => {
      this.toastr.successToastr("Save Succesfully", "Success!", { timeOut: 500 });
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  exportToExcel() {
    const ws: xlsx.WorkSheet =
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Employee Details.xlsx');
    }
}
